import { computed, Ref, shallowRef, watch } from '@vue/composition-api';
import { QueryStatusErrorFromQuery } from '@ax/cache-and-dedupe-vue';
import VueRouter, { Route } from 'vue-router';
import * as SoftwareService from '@ax/data-services-software';
import { showStandardHttpErrorNotification } from '@ax/notifications';
import { DataTablePageState, DataTableState } from '@ax/data-table-state';
import { QueryState } from '@ax/url-query-state';
import { DEFAULT_TABLE_PAGINATION } from '@ax/cosmos/components/Table/table.models';
import { DataTableHeader } from 'vuetify';
import { Severity, SoftwarePackage } from '@ax/data-services-software';
import * as REF from '@ax/vue-utils/ref';
import { useSoftwarePackageMutations } from './use-software-package-mutations';
import { SeverityFilters } from '../models';

export const DefaultSoftwareTableFooter = {
  'items-per-page-options': [10, 25, 50, 100],
  'items-per-page-text': 'Software per page:',
};

type SoftwareTableHeaderValue = keyof SoftwarePackage | 'actions';

export interface SoftwareTableHeader extends DataTableHeader<SoftwarePackage> {
  value: SoftwareTableHeaderValue;
}

export interface SoftwarePageState extends DataTablePageState<SoftwarePackage> {
  readonly automox_supported: boolean;
  readonly os: readonly number[];
  readonly severity: readonly Severity[];
  readonly group_ignored: boolean | undefined;
  readonly software_title: readonly string[];
  readonly vulnerability: readonly string[];
  readonly show_days_exposed: boolean;
}

export const DefaultSoftwareTableHeaders: SoftwareTableHeader[] = [
  {
    text: 'Software Name',
    divider: true,
    sortable: true,
    value: 'display_name',
    width: '25rem',
  },
  {
    text: 'Version',
    divider: true,
    sortable: false,
    value: 'package_version',
    width: '10rem',
  },
  {
    text: 'OS',
    divider: true,
    sortable: false,
    value: 'os_family_name',
    width: '3.5rem',
  },
  {
    text: 'OS Version',
    divider: true,
    sortable: false,
    value: 'formatted_os_version',
    width: '20rem',
  },
  {
    text: 'Severity',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'severity',
  },
  {
    text: 'Days Exposed',
    divider: true,
    sortable: true,
    value: 'create_time',
  },
  {
    text: 'Ignored',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'group_ignored',
  },
  {
    text: 'Impacted',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    align: 'start',
    value: 'devices_needed',
  },
  {
    text: 'Updated',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    align: 'start',
    value: 'patch_installed',
  },
  {
    text: 'Requires Reboot',
    class: 'tw-whitespace-no-wrap',
    align: 'center',
    divider: true,
    sortable: false,
    value: 'requires_reboot',
  },
  {
    text: 'Actions',
    class: 'tw-whitespace-no-wrap',
    align: 'center',
    divider: true,
    filterable: false,
    sortable: false,
    value: 'actions',
  },
];

export const PolicySoftwareHeaders: readonly SoftwareTableHeader[] = [
  {
    text: 'Package List',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'display_name',
  },
];

export const SoftwareBaseQueryState: Partial<SoftwarePageState> = {
  automox_supported: true,
  group_ignored: undefined,
  os: [],
  severity: [],
  vulnerability: [],
  software_title: [],
};

const storageConfig = {
  key: 'ax-software-prefs',
  store: localStorage,
};

function showErrorNotifications(
  error: Ref<
    | QueryStatusErrorFromQuery<SoftwareService.GetSoftware>
    | QueryStatusErrorFromQuery<SoftwareService.GetPolicySoftware>
    | undefined
  >,
) {
  watch(error, (newError) => {
    if (newError !== undefined) {
      showStandardHttpErrorNotification(
        newError.type === 'ApiClientError' ? newError.error : undefined,
      );
    }
  });
}

export interface UseSoftwarePageStateProps {
  readonly router: VueRouter;
  readonly route: Route;
  readonly orgId: Ref<number>;
  readonly totalOses: Ref<number>;
}

export function useSoftwarePageState({
  router,
  route,
  orgId,
  totalOses,
}: UseSoftwarePageStateProps) {
  /**
   * This is the current query string for requesting `software`
   */
  const currentQuery = shallowRef<string>();

  const { error, packages, ...remainingResponse } =
    SoftwareService.useSoftware(currentQuery);

  /**
   * This will watch for errors and display them to the user.
   */
  showErrorNotifications(error);

  const { tableState, queryState, updateQueryState, updateColumnsState } =
    DataTableState.synchronize<
      SoftwareService.SoftwarePackage,
      SoftwarePageState
    >(
      route,
      router,
      {
        o: { defaultValue: orgId.value },
        limit: {
          defaultValue: DEFAULT_TABLE_PAGINATION,
          storeInBrowser: true,
          apiKeyTranslation: 'l',
        },
        sort_dir: {
          defaultValue: 'desc',
          apiKeyTranslation: 'dir',
          legacyUiKeyTranslation: 'sort',
        },
        sort_by: {
          defaultValue: 'severity',
          apiKeyTranslation: 'sort',
          apiValueTranslations: new Map()
            .set('devices_needed', 'devices-needed')
            .set('create_time', 'date')
            .set('patch_installed', 'patch-installed')
            .set('display_name', 'display-name'),
          legacyUiKeyTranslation: 'order_by',
          legacyUiValueTranslations: new Map()
            .set('devices-needed', 'devices_needed')
            .set('date', 'create_time'),
        },
        page: {
          defaultValue: 1,
          legacyUiValueTranslations: new Map().set(0, 1),
          apiKeyTranslation: 'p',
        },
        columns: {
          defaultValue: [],
          storeInBrowser: true,
          excludeFromApiUrl: true,
        },
        automox_supported: {
          defaultValue: true,
          isApiFilter: true,
          apiKeyTranslation: 'is_managed',
          apiValueTranslations: new Map().set(true, 1).set(false, null),
        },
        group_ignored: {
          defaultValue: undefined,
          isApiFilter: true,
          apiKeyTranslation: 'group_ignored',
          apiValueTranslations: new Map().set(true, 1).set(false, null),
        },
        q: { defaultValue: [], apiKeyTranslation: 'filter' },
        os: {
          defaultValue: [],
          isApiFilter: true,
          apiKeyTranslation: 'os_id',
          dataFilterMaxLength: () => totalOses.value,
        },
        severity: {
          defaultValue: [],
          isApiFilter: true,
          dataFilterMaxLength: () => SeverityFilters.length,
        },
        vulnerability: {
          defaultValue: [],
          isApiFilter: true,
          apiKeyTranslation: 'cves',
        },
        software_title: {
          defaultValue: [],
          isApiFilter: true,
          apiKeyTranslation: 'display_name',
        },
        filter_panel_open: {
          defaultValue: true,
          storeInBrowser: true,
          excludeFromBrowserUrl: true,
          excludeFromApiUrl: true,
        },
        show_days_exposed: {
          defaultValue: true,
          storeInBrowser: true,
          excludeFromBrowserUrl: true,
          excludeFromApiUrl: true,
        },
      },
      {
        storageConfig,
        callback: ({ apiQuery }) => {
          /**
           * Update the `currentQuery` with the stringified `apiQuery`. This
           * will trigger a new API request (if it is not already cached)
           */
          currentQuery.value =
            QueryState.toApiUrlQueryStringWithBrackets(apiQuery);
        },
      },
    );

  const hasNoPackages = REF.map_(
    packages,
    (softwarePackages) => softwarePackages.length === 0,
  );

  /**
   * Toggle the `show_days_exposed` value in `queryState`
   */
  function toggleDaysExposed() {
    updateQueryState(
      {
        show_days_exposed: !queryState.value.show_days_exposed,
      },
      {
        bypassUiUrlUpdate: true,
        bypassApiUrlUpdate: true,
      },
    );
  }

  /**
   * Update the `q` parameter in `queryState` with the given values
   * @param query `string[]`
   */
  function updateQueryValues(query: string[]) {
    updateQueryState({
      q: query,
    });
  }

  const { patchSoftware } = useSoftwarePackageMutations({ orgId });

  /**
   * Used to determine if the sidebar should be hidden or visible.
   */
  const showSidebar = computed({
    get() {
      return queryState.value.filter_panel_open;
    },
    set(filter_panel_open?: boolean) {
      updateQueryState(
        { filter_panel_open },
        { bypassUiUrlUpdate: true, bypassApiUrlUpdate: true },
      );
    },
  });

  return {
    tableState,
    queryState,
    updateQueryState,
    packages,
    hasNoPackages,
    updateColumnsState,
    toggleDaysExposed,
    updateQueryValues,
    patchSoftware,
    showSidebar,
    ...remainingResponse,
  };
}
