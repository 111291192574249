import { SoftwarePackage } from '@ax/data-services-software';

/**
 * Use to calculate a package status link
 * @param item `SoftwarePackage`
 * @param status `'Installed' | 'Not Installed'`
 * @param orgId `number`
 * @returns `RouteLocation`
 */
export function getPackageStatusLink(
  item: SoftwarePackage,
  status: 'Installed' | 'Not Installed',
  orgId: number,
) {
  return {
    path: '/devices',
    query: {
      o: orgId,
      q: [
        `Package Status: ${status}`,
        `Version: ${item.display_name} ${item.package_version} [${item.package_version_id}]`.replace(
          /,/g,
          '%2C',
        ),
      ].join(','),
    },
  };
}
