import VueRouter from 'vue-router';
import { setItemInWebStorage } from '@ax/web-storage-wrapper';

/**
 * Opens the Remote Control route in a new window.
 *
 * @param router VueRouter
 * @param device Device
 */
let rcWindow: Window | null;
export function openRemoteScreen(
  router: VueRouter,
  device: { id: number; uuid: string; organization_id: number },
) {
  const isWindowOpen: boolean = rcWindow != null && rcWindow.closed === false;
  if (isWindowOpen && rcWindow) {
    // Focuses on the existing window if it's already open instead of opening a new window and starting a new connection
    if (rcWindow.name === device.uuid) {
      rcWindow.focus();
      return;
    }

    // Otherwise close the current connection before starting a new one
    rcWindow.close();
  }

  const rcRoute = router.resolve({
    name: 'remoteControl',
    params: {
      deviceId: `${device.id}`,
    },
    query: { o: `${device.organization_id}` },
  });

  // Opens a window at 80% of the current window size in the center
  // Makes sure to offset for multiple monitors
  const width = window.screen.width * 0.8;
  const height = window.screen.height * 0.8;
  const left = window.screen.width / 2 - width / 2 + window.screenLeft;
  const top = window.screen.height / 2 - height / 2 + window.screenTop;

  rcWindow = window.open(
    rcRoute.href,
    device.uuid,
    `fullscreen=no,width=${width},height=${height},top=${top},left=${left}`,
  );
  if (rcWindow) {
    setItemInWebStorage(true, {
      key: 'ax-rc-autoconnect',
      store: rcWindow.sessionStorage,
    });
  }
} // openRemoteScreen()/ openRemoteScreen()
