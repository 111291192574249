import * as SoftwareService from '@ax/data-services-software';
import {
  showStandardHttpErrorNotification,
  showSuccessNotificationLazy,
} from '@ax/notifications';
import { Ref, shallowRef } from '@vue/composition-api';
import { createSharedComposable } from '@vueuse/core';

export function ignoreSoftwarePackageImpl(
  softwarePackage: SoftwareService.SoftwarePackage,
  group_ignored: boolean,
  orgId: number,
) {
  return SoftwareService.ignoreSoftware(
    softwarePackage.package_id,
    orgId,
    {
      ...softwarePackage,
      group_ignored,
      group_deferred_until: null,
    },
    { loaderEnabled: false },
  )
    .then(showSuccessNotificationLazy('Success: Changes applied successfully'))
    .catch(showStandardHttpErrorNotification);
}

export function patchSoftwarePackageImpl(
  softwarePackage: SoftwareService.SoftwarePackage,
  orgId: number,
) {
  return SoftwareService.patchSoftware(
    softwarePackage.package_version_id,
    orgId,
    { loaderEnabled: false },
  )
    .then(showSuccessNotificationLazy('Success: Update queued successfully'))
    .catch(showStandardHttpErrorNotification);
}

export interface UseSoftwarePackageMutationsProps {
  readonly orgId: Ref<number>;
  /**
   * The below are used for testing purposes
   */
  readonly ignoreSoftwarePackage?: typeof ignoreSoftwarePackageImpl;
  readonly patchSoftwarePackage?: typeof patchSoftwarePackageImpl;
}

function useSoftwarePackageMutationsState(
  props: UseSoftwarePackageMutationsProps,
) {
  const {
    orgId,
    ignoreSoftwarePackage = ignoreSoftwarePackageImpl,
    patchSoftwarePackage = patchSoftwarePackageImpl,
  } = props;

  /**
   * This ref maintains a list of all `SoftwarePackage` ids that have in flight mutations.
   */
  const packageIdsWithPendingActions = shallowRef<readonly number[]>([]);

  /**
   * Add a `SoftwarePackage`'s id to `packageIdsWithPendingActions`
   */
  function addPendingPackage(item: SoftwareService.SoftwarePackage) {
    const currentPackageIds = new Set(packageIdsWithPendingActions.value);
    currentPackageIds.add(item.package_version_id);
    packageIdsWithPendingActions.value = [...currentPackageIds];
  }

  /**
   * Create a thunk that will remove a `SoftwarePackage`'s id from `packageIdsWithPendingActions`
   * @param item `SoftwarePackage`
   * @returns `() => void`
   */
  function removePendingPackage(item: SoftwareService.SoftwarePackage) {
    return () => {
      const pendingId = item.package_version_id;
      packageIdsWithPendingActions.value =
        packageIdsWithPendingActions.value.filter((id) => id !== pendingId);
    };
  }

  /**
   * Submit a request to ignore a `SoftwarePackage`
   */
  function ignoreSoftware(item: SoftwareService.SoftwarePackage) {
    addPendingPackage(item);
    return ignoreSoftwarePackage(item, true, orgId.value).finally(
      removePendingPackage(item),
    );
  }

  /**
   * Submit a request to unignore a `SoftwarePackage`
   */
  function unignoreSoftware(item: SoftwareService.SoftwarePackage) {
    addPendingPackage(item);
    return ignoreSoftwarePackage(item, false, orgId.value).finally(
      removePendingPackage(item),
    );
  }

  /**
   * Submit a request to patch a `SoftwarePackage` now
   */
  function patchSoftware(item: SoftwareService.SoftwarePackage) {
    addPendingPackage(item);
    return patchSoftwarePackage(item, orgId.value).finally(
      removePendingPackage(item),
    );
  }

  /**
   * Checks if a `SoftwarePackage` has any in flight mutations.
   * @param item `SoftwarePackage`
   * @returns `boolean`
   */
  function packageIsLoading(item: SoftwareService.SoftwarePackage) {
    return packageIdsWithPendingActions.value.includes(item.package_version_id);
  }

  return {
    packageIdsWithPendingActions,
    packageIsLoading,
    ignoreSoftware,
    unignoreSoftware,
    patchSoftware,
  };
}

/**
 * Using a shared composable means that multiple consumers of this composition
 * will all get the same information in terms of whether or not a given
 * `SoftwarePackage` has in flight mutations
 */
export const useSoftwarePackageMutations = createSharedComposable(
  useSoftwarePackageMutationsState,
);
