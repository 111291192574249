var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',{attrs:{"footer-props":_vm.footerProps,"headers":_vm.headers,"active-headers":_vm.activeHeaders,"items":_vm.packages,"options":_vm.tableState,"server-items-length":_vm.totalPackages,"data-test-id":"software-page-table","item-key":"id","loading-text":"Loading software...","no-data-text":"No software packages available"},on:{"update:options":function($event){return _vm.$emit('update:table-state', $event)},"columns-updated":function($event){return _vm.$emit('columns-updated', $event)}},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('div',{staticClass:"mx-4 mb-2"},[_c('ax-combobox',{staticClass:"mb-0",attrs:{"value":_vm.queryState.q,"prepend-inner-icon":_vm.mdiMagnify,"label":"Search Software","chips":"","multiple":"","clearable":"","hide-details":"","append-icon":null,"data-test-id":"software-search-box"},on:{"change":function($event){return _vm.$emit('query-values-updated', $event)}}})],1)]},proxy:true},{key:"header.create_time",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-inline-block"},[_c('ax-tooltip',{attrs:{"top":"","data-test-id":"test"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({attrs:{"data-test-id":"software-table-toggle-days-exposed"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-days-exposed')}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Switch between Days Exposed and Date Detected","small":""}},[_vm._v(" "+_vm._s(_vm.mdiCalendarSyncOutline)+" ")])],1)]}}],null,true)},[_vm._v(" Click to switch between Days Exposed and Date Detected. ")]),(_vm.queryState.show_days_exposed)?[_vm._v("Days Exposed")]:[_vm._v(_vm._s(header.text))]],2)]}},{key:"item.os_family_name",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('ax-os-icon',{attrs:{"os-family":item.os_family_name}})],1)]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('ax-cve-tooltip',{attrs:{"cves":item.cves,"label":item.severity || 'unknown'}})],1)]}},{key:"item.create_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.queryState.show_days_exposed ? item.days_exposed : item.formatted_create_time)+" ")]}},{key:"item.group_ignored",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.group_ignored ? 'Yes' : 'No')+" ")]}},{key:"item.devices_needed",fn:function(ref){
var item = ref.item;
return [(item.devices_needed === 0)?[_vm._v(_vm._s(item.devices_needed))]:_c('router-link',{staticClass:"enlarged-link",attrs:{"to":_vm.getPackageNotInstalledLink(item)}},[_vm._v(" "+_vm._s(item.devices_needed)+" ")])]}},{key:"item.patch_installed",fn:function(ref){
var item = ref.item;
return [(item.patch_installed === 0)?[_vm._v(_vm._s(item.patch_installed))]:_c('router-link',{staticClass:"enlarged-link",attrs:{"to":_vm.getPackageInstalledLink(item)}},[_vm._v(" "+_vm._s(item.patch_installed)+" ")])]}},{key:"item.requires_reboot",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requires_reboot ? 'Yes' : 'No')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',{staticClass:"row-min-height d-flex justify-center align-center"},[_c('ax-table-row-actions-column',{attrs:{"loading":_vm.isLoading && _vm.packageIsLoading(item)}},[_c('v-list-item',{on:{"click":function($event){return _vm.patchNow(item)}}},[_c('v-list-item-title',[_vm._v("Patch Now")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.ignoreSoftware(item)}}},[_c('v-list-item-title',[_vm._v("Ignore")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.unignoreSoftware(item)}}},[_c('v-list-item-title',[_vm._v("Unignore")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }