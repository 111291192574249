import { unref } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { FlagValue } from '@ax/feature-flag';
import { OSFamilies } from '@ax/data-services-devices/models/oses';
import { DeviceStatuses } from '@ax/data-services-devices/models';

/**
 * Determines if a device meets all criteria to have Remote Control access with the exception of device status.
 *
 * @param device MaybeRef<Device>
 * @param remoteControlFlag MaybeRef<FlagValue>
 * @param canControlDevice MaybeRef<boolean | undefined>
 * @param hasBillingPlanWithRemoteControl MaybeRef<boolean>
 * @returns boolean
 */
export function canRemoteControl(
  osFamily: MaybeRef<OSFamilies>,
  remoteControlFlag: MaybeRef<FlagValue>,
  canControlDevice: MaybeRef<boolean | undefined>,
  hasBillingPlanWithRemoteControl: MaybeRef<boolean>,
): boolean {
  return (
    unref(remoteControlFlag) === true &&
    unref(osFamily) === OSFamilies.windows &&
    unref(canControlDevice) === true &&
    unref(hasBillingPlanWithRemoteControl) === true
  );
}

/**
 * Determines if a device should be considered ready for a remote control session.
 *
 * @param deviceConnected boolean
 * @param deviceStatus DeviceStatuses
 * @returns boolean
 */
export function isRemoteControlReady(
  deviceConnected: boolean,
  deviceStatus: DeviceStatuses,
): boolean {
  return deviceConnected && deviceStatus !== DeviceStatuses.initializing;
}
